<template>
	<div class="form-group" v-if="loaded">
		<div class="data-group">
			<div class="title">{{ $t('salary.contract-data.title') }}</div>
		</div>
		<div class="form">
			<div class="input-group w-100">
				<div class="label-header">
					<label class="label">{{ $t('salary.contract-data.contract-type') }}*</label>
					<div v-if="validator.selectedContractType.$error" class="error-msg">{{ $t('validator.required') }}</div>
				</div>
				<div class="input-box" v-bind:class="{has_error: validator.selectedContractType.$error}">
					<div class="icon-left"><icon-contract /></div>
					<div class="icon-right" v-if="contract_data.selectedContractType != ''" @click.stop="contract_data.selectedContractType = ''"><icon-close class="icon-clear" /></div>
					<multiselect 
						v-model="contract_data.selectedContractType"
						v-bind:class="{populate: contract_data.selectedContractType != ''}"
						class="select-tags-tbf"
						:options="contractTypes"
						:allow-empty="true"
						:show-labels="false"
						track-by="id" 
						label="name"
						>
						<template slot="placeholder" slot-scope="props">
							<span class="text">
								{{ $t('salary.contract-data.contract-type-ph') }}
							</span>
						</template>
						<template slot="noResult">{{ $t('create-user.no-results') }}</template>
						<template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
					</multiselect>
				</div>
			</div>
			<div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
				<div class="label-header">
					<label class="label">{{ $t('salary.contract-data.mark') }}</label>
				</div>
				<div class="input-box">
					<div class="icon-left"><icon-contract /></div>
					<div class="icon-right" v-if="contract_data.mark != ''" @click.stop="contract_data.mark = ''"><icon-close class="icon-clear" /></div>
					<input type="text" :placeholder="$t('salary.contract-data.mark-ph')" class="input-text" v-model="contract_data.mark">
				</div>
			</div>
			<div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
				<div class="label-header">
					<label class="label">{{ $t('salary.contract-data.number-contract') }}*</label>
					<div v-if="validator.contract_number.$error" class="error-msg">{{ $t('validator.required') }}</div>
				</div>
				<div class="input-box" v-bind:class="{has_error: validator.contract_number.$error}">
					<div class="icon-left"><icon-contract /></div>
					<div class="icon-right" v-if="contract_data.contract_number != ''" @click.stop="contract_data.contract_number = ''"><icon-close class="icon-clear" /></div>
					<input type="number" :placeholder="$t('salary.contract-data.number-contract-ph')" class="input-text" v-model="contract_data.contract_number">
				</div>
			</div>
			<div class="delimeter-form"></div>
			<div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
				<div class="label-header">
					<label class="label">{{ $t('salary.contract-data.contract-date') }}*</label>
					<div v-if="validator.date_contract.$error" class="error-msg">{{ $t('validator.required') }}</div>
				</div>
				<div class="input-box" v-bind:class="{has_error: validator.date_contract.$error}">
					<div class="icon-left"><icon-calendar /></div>
					<div class="icon-right"  v-if="Object.keys(contract_data.date_contract).length" @click.stop="contract_data.date_contract = {}"><icon-close class="icon-clear" /></div>
					<FunctionalCalendar
						ref="CalendarDateOfEmployment"
						v-model="contract_data.date_contract"
						class="calendar-tbf"
						:configs="calendarConfigs"
						@input="changeDateContract"
					>
					<template v-slot:datePickerInput="props">
						<input
						class="vfc-single-input custom-input-picker"
						type="text"
						:value="$options.filters.moment(props.selectedDate, 'DD MMM YYYY')" 
						v-if="Object.keys(contract_data.date_contract).length"/>
						<div class="vfc-single-input custom-placeholder-picker" v-else>{{ $t('salary.contract-data.contract-date-ph') }}</div>
					</template>
					</FunctionalCalendar>
				</div>
			</div>
			<div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
				<div class="label-header">
					<label class="label">{{ $t('salary.contract-data.first_day_of_activity') }}*</label>
					<div v-if="validator.first_day_of_activity.$error" class="error-msg">{{ $t('validator.required') }}</div>
				</div>
				<div class="input-box" v-bind:class="{has_error: validator.first_day_of_activity.$error}">
					<div class="icon-left"><icon-calendar /></div>
					<div class="icon-right"  v-if="Object.keys(contract_data.first_day_of_activity).length" @click.stop="contract_data.first_day_of_activity = {}"><icon-close class="icon-clear" /></div>
					<FunctionalCalendar
						ref="CalendarDateOfEmployment"
						v-model="contract_data.first_day_of_activity"
						class="calendar-tbf"
						:configs="calendarConfigsFirstDay"
					>
					<template v-slot:datePickerInput="props">
						<input
						class="vfc-single-input custom-input-picker"
						type="text"
						:value="$options.filters.moment(props.selectedDate, 'DD MMM YYYY')" 
						v-if="Object.keys(contract_data.first_day_of_activity).length"/>
						<div class="vfc-single-input custom-placeholder-picker" v-else>{{ $t('salary.contract-data.first_day_of_activity-ph') }}</div>
					</template>
					</FunctionalCalendar>
				</div>
			</div>
			<div class="input-group w-100">
				<div class="label-header">
					<label class="label">{{ $t('salary.contract-data.contract_observations') }}</label>
				</div>
				<div class="input-box bg-white">
					<textarea rows="3" :placeholder="$t('salary.contract-data.contract_observations-ph')" v-model="contract_data.contract_observations"></textarea>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import IconContract from '../../../../Icons/Contract'
	import IconCalendar from '../../../../Icons/Calendar'
	import IconClose from '../../../../Icons/Close'

	export default {
		components: {
			
			IconContract,
			IconCalendar,
			IconClose
		},
		props: {
			contract_data: Object,
			validator: Object,
			contractTypes: Array
		},
		data() {
			return {
				loaded: false,
				calendarConfigs: {
					dateFormat: 'yyyy-mm-dd',
					isDatePicker: true,
					isDateRange: false,
					isModal: true,
					isAutoCloseable: true,
					changeMonthFunction: true,
					changeYearFunction: true
				},
				calendarConfigsFirstDay: {
					dateFormat: 'yyyy-mm-dd',
					isDatePicker: true,
					isDateRange: false,
					isModal: true,
					isAutoCloseable: true,
					limits: {min: '', max : ''}
				}
			}
		},
		async mounted(){
			setTimeout(() => {
				this.loaded = true
				setTimeout(() => {
					$('.opacity-page').addClass('show')
				}, 0)
			}, 0)
		},
		methods: {
			changeDateContract() {
				if(this.contract_data.date_contract.selectedDate && this.contract_data.first_day_of_activity.selectedDate && this.contract_data.date_contract.selectedDate > this.contract_data.first_day_of_activity.selectedDate) {
					this.contract_data.first_day_of_activity = {}
					this.calendarConfigsFirstDay.limits.min = this.contract_data.date_contract.selectedDate
				} else if(this.contract_data.date_contract.selectedDate) {
					this.calendarConfigsFirstDay.limits.min = this.contract_data.date_contract.selectedDate
				}
			}
		}
	}
</script>